<template>
  <page-content>
    <!-- <img src="@/assets/mock/header.png" style="    width: 100%;" /> -->
    <!-- <page-title
      heading="ยินดีต้อนรับเข้าสู่เว็บไซต์ E-LEARNING D.A.R.E. Thailand"
    ></page-title> -->

    <!-- <banner-image-home></banner-image-home> -->
    <div
      :style="{
        backgroundImage: `url(${require('@/assets/backgroup_home.png')})`,
      }"
    >
      <div style="padding: 50px; ">
      <a href="/home">
        <p style="font-size: 1.5rem; color: #fff;">
          <b-icon icon="chevron-left" aria-hidden="true"></b-icon> ย้อนกลับ
        </p>
      </a>
      <div
        style="padding: 3rem; "
        class="app-content--inner"
      >
        <div class="row">
          <div class="app-content--inner">
          <div class="row">
            <div class="col-8">
              <h3 class="mt-4 text-left" style="color: #fff;"><b>English Language Company</b></h3>
              <div class="row">
                <div class="col-4">
                  <p class="mt-4 text-left" style="margin-top: -20px; color: #fff;">
                    <b>รหัสวิชา 14200</b>
                  </p>
                </div>
                <div class="col-8">
                  <p
                    class="mt-4 text-left"
                    style="margin-top: -20px; margin-left: -50px; color: #fff;"
                  >
                    <img src="@/assets/mock/person.png" style="height: 18px;" />
                    <b>10/20 คน</b>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-4" style="margin-top: 20px;">
              <div>
                <button class="btn btn-lg btn-primary btn-block form-group">
                  <span>
                    ลงทะเบียน
                  </span>
                </button>
              </div>
            </div>
            <br />
            <div class="col-8">
              <img src="@/assets/img/eng1.jpeg" style="width: 100%;" />
            </div>
            <div
              class="col-4"
              style="    border: 1px solid #e3e3e3; border-radius: 20px;"
            >
              <br />
              <div class="text-center">
                <p
                  style="
                text-decoration: underline;
                color: #000;
                text-decoration-color: #000;
                text-decoration-thickness: 3px;
              "
                >
                  <b>อาจารย์ผู้สอน</b>
                </p>
                <p >
                  <b>ดร.ปิยนุช ตั้งเจริญธรรมผล</b>
                </p>
                <!-- <div class="text-center">
              <button
                class="btn btn-lg btn-primary btn-block form-group "
                style="color: #fff; width: 100%;" >
                <span>
                  ลงทะเบียน
                </span>
              </button>
            </div> -->
              </div>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-8">
              <p
                style="
              font-size: 1.5rem;
                text-decoration: underline;
                color: #000;
                text-decoration-color: #000;
                text-decoration-thickness: 3px;
              "
              >
                <b>รายละเอียดหลักสูตร</b>
              </p>
              <p
                style="
              font-size: 1rem;
              "
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-8">
              <p
                style="
              font-size: 1.5rem;
                text-decoration: underline;
                color: #000;
                text-decoration-color: #000;
                text-decoration-thickness: 3px;
              "
              >
                <b>บทเรียน</b>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-8">
              <div class="row">
                <div class="col-2">
                  <p
                    style="
              font-size: 2.5rem;
              color: rgb(143 143 143);
              "
                  >
                    01
                  </p>
                </div>
                <div class="col-7">
                  <p
                    style="
              margin-top: 10px;
              font-size: 1.5rem;
              "
                  >
                    <a href="/lesson-detail">
                      ปฐมนิเทศ
                    </a>
                  </p>
                </div>
                <div class="col-3">
                  <p
                    style="
              margin-top: 15px;
              font-size: 1rem;
              color: rgb(143 143 143);
              "
                  >
                    1 ม.ค. 65
                  </p>
                </div>
                <div class="col-12" style="margin-top: -30px;">
                  <v-divider></v-divider>
                </div>
              </div>
            </div>
            <div class="col-8">
              <div class="row">
                <div class="col-2">
                  <p
                    style="
              font-size: 2.5rem;
              color: rgb(143 143 143);
              "
                  >
                    02
                  </p>
                </div>
                <div class="col-7">
                  <p
                    style="
              margin-top: 10px;
              font-size: 1.5rem;
              "
                  >
                    ภาษาอังกฤษพื้นฐาน
                  </p>
                </div>
                <div class="col-3">
                  <p
                    style="
              margin-top: 15px;
              font-size: 1rem;
              color: rgb(143 143 143);
              "
                  >
                    2 ม.ค. 65
                  </p>
                </div>
                <div class="col-12" style="margin-top: -30px;">
                  <v-divider></v-divider>
                </div>
              </div>
            </div>
            <div class="col-8">
              <div class="row">
                <div class="col-2">
                  <p
                    style="
              font-size: 2.5rem;
              color: rgb(143 143 143);
              "
                  >
                    03
                  </p>
                </div>
                <div class="col-7">
                  <p
                    style="
              margin-top: 10px;
              font-size: 1.5rem;
              "
                  >
                    ธุรกิจกับการสื่อสาร
                  </p>
                </div>
                <div class="col-3">
                  <p
                    style="
              margin-top: 15px;
              font-size: 1rem;
              color: rgb(143 143 143);
              "
                  >
                    3 ม.ค. 65
                  </p>
                </div>
                <div class="col-12" style="margin-top: -30px;">
                  <v-divider></v-divider>
                </div>
              </div>
            </div>
          </div>

        </div>
        </div>
        <br />
        <div class="row">
          <div class="col-8">
            <p
              style="
              font-size: 1.5rem;
                text-decoration: underline;
                color: #000;
                text-decoration-color: #000;
                text-decoration-thickness: 3px;
              "
            >
              <b>ข้อสอบ/แบบฝึกหัด</b>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div
                class="col-1"
              >
              </div>
              <div
                class="col-5"
                style="border: 1px solid; border-radius: 10px; margin: 10px;"
              >
                <div style="padding: 20px;">
                  <p class="text-center"><b>ข้อสอบกลางภาค</b></p>
                </div>
                <div>
                  <p class="text-left" style="font-size: 0.8rem;">
                    สอบกลางภาค : วิชา English Language Company
                  </p>
                  <p class="text-left" style="font-size: 0.8rem;">
                    วันที่ : 11 มิ.ย. 64 เวลา : 09:00-11:00น.
                  </p>
                  <p class="text-left" style="font-size: 0.8rem;">
                    คะแนน: 100 คะแนน
                  </p>
                  <div>
                    <button class="btn btn-lg btn-primary btn-block form-group">
                      <span>
                        ทำข้อสอบ
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div
                class="col-5"
                style="border: 1px solid; border-radius: 10px; margin: 10px;"
              >
                <div style="padding: 20px;">
                  <p class="text-center"><b>ข้อสอบปลายภาค</b></p>
                </div>
                <div>
                  <p class="text-left" style="font-size: 0.8rem;">
                    สอบกลางภาค : วิชา English Language Company
                  </p>
                  <p class="text-left" style="font-size: 0.8rem;">
                    วันที่ : 11 มิ.ย. 64 เวลา : 09:00-11:00น.
                  </p>
                  <p class="text-left" style="font-size: 0.8rem;">
                    คะแนน: 100 คะแนน
                  </p>
                  <button class="btn btn-lg btn-primary btn-block form-group">
                    <span>
                      ทำข้อสอบ
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-8">
            <p
              style="
              font-size: 1.5rem;
                text-decoration: underline;
                color: #000;
                text-decoration-color: #000;
                text-decoration-thickness: 3px;
              "
            >
              <b>ผลการเรียน</b>
            </p>
          </div>
        </div>
        <div class="row">
          <div
            class="col-12"
            style="border: 1px solid rgb(227 227 227); border-radius: 10px; margin: 10px;"
          >
            <div>
              <br />
              <a href="/reason-result">
                <p class="text-center"><b>ดูผลการเรียน</b></p>
              </a>
            </div>
          </div>
        </div>
      <br>
        <div class="row">
          <div class="col-8">
            <p
              style="
              font-size: 1.5rem;
                text-decoration: underline;
                color: #000;
                text-decoration-color: #000;
                text-decoration-thickness: 3px;
              "
            >
              <b>รายชื่อผู้เรียน</b>
            </p>
          </div>
        </div>
        <div class="row">
          <div
            class="col-12"
            style="border: 1px solid rgb(227 227 227); border-radius: 10px; margin: 10px;"
          >
            <div>
              <br />
              <a href="/student-list">
                <p class="text-center"><b>ดูรายชื่อผู้เรียนทั้งหมด</b></p>
              </a>
            </div>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-8">
            <p
              style="
              font-size: 1.5rem;
                text-decoration: underline;
                color: #000;
                text-decoration-color: #000;
                text-decoration-thickness: 3px;
              "
            >
              <b>ประเมินครูผู้สอน</b>
            </p>
          </div>
        </div>
        <div class="row">
          <div
            class="col-12"
            style="border: 1px solid rgb(227 227 227); border-radius: 10px; margin: 10px;"
          >
            <div>
              <br />
              <a>
                <p class="text-center"><b>ไปที่หน้าประเมินครูผู้สอน</b></p>
              </a>
            </div>
          </div>
        </div>
      </div>

        <!-- <youtube
        :video-id="videoId"
        ref="youtube"
        @playing="playing"
        @paused="pause"
      ></youtube>
      <button @click="playVideo">play</button> -->
      </div>
    </div>
  </page-content>
</template>

<script>
import PageContent from "../../components/layout/PageContent";
// import VueYoutube from 'vue-youtube'
// import PageTitle from "../components/layout/PageTitle";
// import BannerImageHome from "../components/carousel/BannerImageHome";
// import News from "../components/carousel/News";
// import Reason from "../components/carousel/Reason";

export default {
  components: {
    PageContent,
    // VueYoutube
    // PageTitle,
    // BannerImageHome,
    // News,
    // Reason,
  },

  data() {
    return {
      videoId: "lG0Ys-2d4MA",
    };
  },
  methods: {
    playVideo() {
      this.player.playVideo();
    },
    playing() {
      console.log("we are watching!!!");
    },
    pause() {
      console.log("pause!!!");
    },
  },
  computed: {
    player() {
      return this.$refs.youtube.player;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  &___width-override {
    max-width: 1440px !important;

    @media only screen and (max-width: 1660px) and (min-width: 1420px) {
      max-width: 1140px !important;
    }
    @media only screen and (max-width: 1419px) and (min-width: 1140px) {
      max-width: 960px !important;
    }

    @media only screen and (max-width: 1139px) and (min-width: 770px) {
      max-width: 760px !important;
    }

    @media (max-width: 760px) {
      max-width: 360px !important;
    }
  }
}
</style>
