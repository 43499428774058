import { render, staticRenderFns } from "./reason-result.vue?vue&type=template&id=e4bc302e&scoped=true&"
import script from "./reason-result.vue?vue&type=script&lang=js&"
export * from "./reason-result.vue?vue&type=script&lang=js&"
import style0 from "./reason-result.vue?vue&type=style&index=0&id=e4bc302e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e4bc302e",
  null
  
)

export default component.exports