<template>
  <page-content>
    <!-- <img src="@/assets/mock/header.png" style="    width: 100%;" /> -->
    <!-- <page-title
      heading="ยินดีต้อนรับเข้าสู่เว็บไซต์ E-LEARNING D.A.R.E. Thailand"
    ></page-title> -->

    <!-- <banner-image-home></banner-image-home> -->
    <div
      :style="{
        backgroundImage: `url(${require('@/assets/backgroup_home.png')})`,
      }"
    >
      <div style="padding: 50px; ">
       <a href="/reason-registered">
        <p style="font-size: 1.5rem; color: #fff;">
          <b-icon icon="chevron-left" aria-hidden="true"></b-icon> ย้อนกลับ
        </p>
      </a>
      <br />
      <div
        style="padding: 3rem;background-color: #fff; border-radius: 20px;"
        class="app-content--inner"
      >
        <div class="row">
          <div class="col-12">
            <h3 class="mt-4 text-center" style="font-size: 1.5rem"><b>ผลการเรียน</b></h3>
            <p class="text-center" style="font-size: 1.5rem"><b>English Language Company</b></p>
          </div>
         
           <div class="col-12 text-center" >
              <br>
            <img src="@/assets/img/result.png" style="height: 200px;" />
          </div>
           <div class="col-12">
              <br>
            <p class="text-center" style="font-size: 1.5rem">นาย อนุพล เลิศหล้านภาลัย</p>

            <p class="text-center" style="font-size: 1.5rem">สถานะ: <span style="color: #00CB5D;">สำเร็จการศึกษา </span>      คะแนนเฉลี่ย: 89.00 [A+]</p>
          </div>
          
        </div>
        
        <br />
        <div class="row">
          <div class="col-8">
            <p
              style="
              font-size: 1.5rem;
                text-decoration: underline;
                color: #000;
                text-decoration-color: #000;
                text-decoration-thickness: 3px;
              "
            >
              <b>คะแนนแบบทดสอบ</b>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div
                class="col-2"
                style="background-color: #627C86; border-radius: 10px; margin: 10px;"
              >
                <div style="padding: 10px;">
                  <p class="text-center" style="color: #fff;"><b>บทที่ 1</b></p>
                  <p class="text-center" style="font-size: 1.8rem; color: #fff;">
                    18/20 <span style="font-size: 0.8rem; color: #fff;">คะแนน</span>
                  </p>
                </div>
              </div>
              <div
                class="col-2"
                style="background-color: #627C86; border-radius: 10px; margin: 10px;"
              >
                <div style="padding: 10px;">
                  <p class="text-center" style="color: #fff;"><b>บทที่ 2</b></p>
                  <p class="text-center" style="font-size: 1.8rem; color: #fff;">
                    18/20 <span style="font-size: 0.8rem; color: #fff;">คะแนน</span>
                  </p>
                </div>
              </div>
              <div
                class="col-2"
                style="background-color: #627C86; border-radius: 10px; margin: 10px;"
              >
                <div style="padding: 10px;">
                  <p class="text-center" style="color: #fff;"><b>บทที่ 3</b></p>
                  <p class="text-center" style="font-size: 1.8rem; color: #fff;">
                    18/20 <span style="font-size: 0.8rem; color: #fff;">คะแนน</span>
                  </p>
                </div>
              </div>
              <div
                class="col-2"
                style="background-color: #627C86; border-radius: 10px; margin: 10px;"
              >
                <div style="padding: 10px;">
                  <p class="text-center" style="color: #fff;"><b>บทที่ 4</b></p>
                  <p class="text-center" style="font-size: 1.8rem; color: #fff;">
                    18/20 <span style="font-size: 0.8rem; color: #fff;">คะแนน</span>
                  </p>
                </div>
              </div>
              <div
                class="col-2"
                style="background-color: #627C86; border-radius: 10px; margin: 10px;"
              >
                <div style="padding: 10px;">
                  <p class="text-center" style="color: #fff;"><b>บทที่ 5</b></p>
                  <p class="text-center" style="font-size: 1.8rem;; color: #fff;">
                    18/20 <span style="font-size: 0.8rem; color: #fff;">คะแนน</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-8">
            <p
              style="
              font-size: 1.5rem;
                text-decoration: underline;
                color: #000;
                text-decoration-color: #000;
                text-decoration-thickness: 3px;
              "
            >
              <b>คะแนนสอบ</b>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div
                class="col-1"
              >
              </div>
              <div
                class="col-5"
                style="border: 1px solid; border-radius: 10px; margin: 10px;"
              >
                <div style="padding: 20px;">
                  <p class="text-center"><b>ข้อสอบกลางภาค</b></p>
                </div>
                <div>
                  <p class="text-left" style="font-size: 0.8rem;">
                    สอบกลางภาค : วิชา English Language Company
                  </p>
                  <p class="text-left" style="font-size: 0.8rem;">
                    วันที่ : 11 มิ.ย. 64 เวลา : 09:00-11:00น.
                  </p>
                  <p class="text-left" style="font-size: 0.8rem;">
                    คะแนน: 100 คะแนน
                  </p>
                  <div>
                    <button class="btn btn-lg btn-primary btn-block form-group" style="color: #fff;">
                      <span>
                         <b>
                        87/100
                         </b>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div
                class="col-5"
                style="border: 1px solid; border-radius: 10px; margin: 10px;"
              >
                <div style="padding: 20px;">
                  <p class="text-center"><b>ข้อสอบปลายภาค</b></p>
                </div>
                <div>
                  <p class="text-left" style="font-size: 0.8rem;">
                    สอบกลางภาค : วิชา English Language Company
                  </p>
                  <p class="text-left" style="font-size: 0.8rem;">
                    วันที่ : 11 มิ.ย. 64 เวลา : 09:00-11:00น.
                  </p>
                  <p class="text-left" style="font-size: 0.8rem;">
                    คะแนน: 100 คะแนน
                  </p>
                  <div>
                    <button class="btn btn-lg btn-primary btn-block form-group" style="color: #fff;">
                      <span>
                        <b>
                        90/100
                        </b>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-8">
            <p
              style="
              font-size: 1.5rem;
                text-decoration: underline;
                color: #000;
                text-decoration-color: #000;
                text-decoration-thickness: 3px;
              "
            >
              <b>หนังสือรับรองจบการศึกษา</b>
            </p>
          </div>
        </div>
        <div class="row">
        <div class="col-12" style="margin-top: 20px;">
          <div>
            <button class="btn btn-lg btn-primary btn-block form-group" style="color: #fff;">
              <span>
                <b>พิมพ์หนังสือรับรอง</b>
              </span>
            </button>
          </div>
        </div>
        </div>

        <!-- <youtube
        :video-id="videoId"
        ref="youtube"
        @playing="playing"
        @paused="pause"
      ></youtube>
      <button @click="playVideo">play</button> -->
      </div>
      </div>
    </div>
  </page-content>
</template>

<script>
import PageContent from "../../components/layout/PageContent";
// import VueYoutube from 'vue-youtube'
// import PageTitle from "../components/layout/PageTitle";
// import BannerImageHome from "../components/carousel/BannerImageHome";
// import News from "../components/carousel/News";
// import Reason from "../components/carousel/Reason";

export default {
  components: {
    PageContent,
    // VueYoutube
    // PageTitle,
    // BannerImageHome,
    // News,
    // Reason,
  },

  data() {
    return {
      videoId: "lG0Ys-2d4MA",
    };
  },
  methods: {
    playVideo() {
      this.player.playVideo();
    },
    playing() {
      console.log("we are watching!!!");
    },
    pause() {
      console.log("pause!!!");
    },
  },
  computed: {
    player() {
      return this.$refs.youtube.player;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  &___width-override {
    max-width: 1440px !important;

    @media only screen and (max-width: 1660px) and (min-width: 1420px) {
      max-width: 1140px !important;
    }
    @media only screen and (max-width: 1419px) and (min-width: 1140px) {
      max-width: 960px !important;
    }

    @media only screen and (max-width: 1139px) and (min-width: 770px) {
      max-width: 760px !important;
    }

    @media (max-width: 760px) {
      max-width: 360px !important;
    }
  }
}
</style>
