<template>
  <page-content>
    <!-- <img src="@/assets/mock/header.png" style="    width: 100%;" /> -->
    <!-- <page-title
      heading="ยินดีต้อนรับเข้าสู่เว็บไซต์ E-LEARNING D.A.R.E. Thailand"
    ></page-title> -->

    <!-- <banner-image-home></banner-image-home> -->
     <div
      :style="{
        backgroundImage: `url(${require('@/assets/backgroup_home.png')})`,
      }"
    >
    <br>
    <br>
    <br>
      <div class="container" style="padding: 20px;">
        <div style="background-color: #fff; border-radius: 20px;">
        <div class="row justify-content-center">
           <div class="col-12 col-lg-10 text-right mb-4">
            <br>
            <p style="font-weight: 300; color: #000;">60:00 นาที</p>
            <p style="color: #000; font-size: 0.7rem; margin-top: -20px;">เวลาคงเหลือ</p>
            <hr />
          </div>
          <div class="col-12 col-lg-10 text-center mb-4">
            <h3 style="font-weight: 600; color: #000;">แบบทดสอบ</h3>
            <h3 style="font-weight: 600; line-height: 1.5; ">
              English Language Company
            </h3>
            <h3 style="font-weight: 400; color: #000;">
              บทที่ 1 ปฐมนิเทศ
            </h3>
            <hr />
          </div>
        </div>

        <div class="row justify-content-center mb-5">
          <!-- <div v-if="loadSurvey" class="col-12 d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div> -->

          <div class="col-12 col-lg-10">
            <p class="mb-3" style="font-weight: 300; color: #000;">
              กรุณาเลือกคำตอบที่ถูกต้องเพียง 1 คำตอบ
            </p>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-12 col-lg-10">
            <div>
              <div>
                <!-- Choice -->
                <div class="">
                  <h5 class="ml-3">
                    1. Lorem ipsum dolor sit amet,
                  </h5>

                  <b-form-group class="ml-5">
                    <b-form-radio class="my-3 sub-survey-topic" v-model="question_answers">
                      <h5>
                        Ut enim ad minim
                      </h5>
                    </b-form-radio>
                    <b-form-radio class="my-3 sub-survey-topic">
                      <h5>
                        dolore magna
                      </h5>
                    </b-form-radio>
                    <b-form-radio class="my-3 sub-survey-topic">
                      <h5>
                         Ut enim
                      </h5>
                    </b-form-radio>
                    <b-form-radio class="my-3 sub-survey-topic">
                      <h5>
                        minim veniam
                      </h5>
                    </b-form-radio>
                  </b-form-group>
                </div>
                <div class="">
                  <h5 class="ml-3">
                    2. dolore magna aliqua. Ut enim ad minim veniam
                  </h5>

                  <b-form-group class="ml-5">
                    <b-form-radio class="my-3 sub-survey-topic" v-model="question_answers">
                      <h5>
                        Ut enim ad minim
                      </h5>
                    </b-form-radio>
                    <b-form-radio class="my-3 sub-survey-topic">
                      <h5>
                        dolore magna
                      </h5>
                    </b-form-radio>
                    <b-form-radio class="my-3 sub-survey-topic">
                      <h5>
                         Ut enim
                      </h5>
                    </b-form-radio>
                    <b-form-radio class="my-3 sub-survey-topic">
                      <h5>
                        minim veniam
                      </h5>
                    </b-form-radio>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center" style="padding: 20px;">
          <div class="col-12 col-lg-10 d-flex flex-row justify-content-end">
            <!-- <b-button
          variant="secondary"
          :disabled="isSubmiting"
          @click="
            () => {
              this.$router.push('home');
            }
          "
          >ยกเลิก</b-button
        > -->

            <b-button variant="primary" class="ml-3">
              <span>ส่งคำตอบ</span>
            </b-button>
            
          </div>
        </div>
      </div>
      </div>
    </div>
  </page-content>
</template>

<script>
import PageContent from "../../components/layout/PageContent";
// import PageTitle from "../components/layout/PageTitle";
// import BannerImageHome from "../components/carousel/BannerImageHome";
// import News from "../components/carousel/News";
// import Reason from "../components/carousel/Reason";
export default {
  components: {
    PageContent,
    // PageTitle,
    // BannerImageHome,
    // News,
    // Reason,
  },

  data() {
    return {
      question_answers: ''
    };
  },
};
</script>

<style lang="scss" scoped>
.container {
  &___width-override {
    max-width: 1440px !important;

    @media only screen and (max-width: 1660px) and (min-width: 1420px) {
      max-width: 1140px !important;
    }
    @media only screen and (max-width: 1419px) and (min-width: 1140px) {
      max-width: 960px !important;
    }

    @media only screen and (max-width: 1139px) and (min-width: 770px) {
      max-width: 760px !important;
    }

    @media (max-width: 760px) {
      max-width: 360px !important;
    }
  }
}
</style>
