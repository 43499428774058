<template>
  <page-content>
    <!-- <img src="@/assets/mock/header.png" style="    width: 100%;" /> -->
    <!-- <page-title
      heading="ยินดีต้อนรับเข้าสู่เว็บไซต์ E-LEARNING D.A.R.E. Thailand"
    ></page-title> -->

    <!-- <banner-image-home></banner-image-home> -->
    <div
      :style="{
        backgroundImage: `url(${require('@/assets/backgroup_home.png')})`,
      }"
    >
      <div style="padding: 50px; ">
        <a href="/reason-detail">
          <p style="font-size: 1.5rem; color: #fff;">
            <b-icon icon="chevron-left" aria-hidden="true"></b-icon> ย้อนกลับ
          </p>
        </a>
        <br />
        <div
          style="padding: 3rem;background-color: #fff;"
          class="app-content--inner"
        >
          <div class="row">
            <div class="col-6">
              <h3 class="mt-4 text-left">
                <b>English Language Company (การสอนสด)</b>
              </h3>
              <h3 class="mt-4 text-left"><b>บทที่ 2 พื้นฐาน</b></h3>
            </div>
            <div class="col-3"></div>
            <div class="col-3 text-right" style="margin-top: 20px;">
              <div>
                <button
                  class="btn btn-lg btn-primary btn-block form-group"
                  @click="openAttend()"
                >
                  <span>
                    ลงชื่อเข้าเรียน
                  </span>
                </button>
              </div>
            </div>

            <br />
            <div class="col-12">
              <img
                src="@/assets/in-meeting-chat-small.gif"
                style=" width: 100%;   height: 600px;"
              />
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-8">
              <p
                style="
              font-size: 1.5rem;
                text-decoration: underline;
                color: #000;
                text-decoration-color: #000;
                text-decoration-thickness: 3px;
              "
              >
                <b>เอกสารประกอบการเรียน</b>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-1">
              <img src="@/assets/img/document.png" style="width: 40px;" />
            </div>
            <div class="col-4">
              <p
                style="
              font-size: 1rem;
              "
              >
                <b
                  >สื่อประกอบการเรียนบทที่ 1<br /><span
                    style="font-size: 0.7rem;"
                    >PDF</span
                  ></b
                >
              </p>
            </div>
            <div class="col-1">
              <img src="@/assets/img/document.png" style="width: 40px;" />
            </div>
            <div class="col-4">
              <p
                style="
              font-size: 1rem;
              "
              >
                <b
                  >ภาพประกอบการเรียนบทที่ 1 <br /><span
                    style="font-size: 0.7rem;"
                    >PDF</span
                  ></b
                >
              </p>
            </div>
          </div>
          <br />
          <br />
          <div class="row">
            <div class="col-12">
              <p
                style="
              font-size: 1.5rem;
                text-decoration: underline;
                color: #000;
                text-decoration-color: #000;
                text-decoration-thickness: 3px;
              "
              >
                <b>ผู้เข้าเรียน (10 คน)</b>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-1">
                  <p
                    style="
              margin-top: 10px;
              font-size: 1.3rem;
              "
                  >
                    1.
                  </p>
                </div>
                <div class="col-11">
                  <p
                    style="
              margin-top: 10px;
              font-size: 1.3rem;
              "
                  >
                    นาย สมพงศ์ พรประเสริฐกิจ<br />
                    <span style="font-size: 0.8rem">
                      สังกัด:
                    </span>
                  </p>
                </div>
                <div class="col-12" style="margin-top: -30px;">
                  <v-divider></v-divider>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-1">
                  <p
                    style="
              margin-top: 10px;
              font-size: 1.3rem;
              "
                  >
                    2.
                  </p>
                </div>
                <div class="col-11">
                  <p
                    style="
              margin-top: 10px;
              font-size: 1.3rem;
              "
                  >
                    นาย สมพงศ์ พรประเสริฐกิจ<br />
                    <span style="font-size: 0.8rem">
                      สังกัด:
                    </span>
                  </p>
                </div>
                <div class="col-12" style="margin-top: -30px;">
                  <v-divider></v-divider>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-1">
                  <p
                    style="
              margin-top: 10px;
              font-size: 1.3rem;
              "
                  >
                    3.
                  </p>
                </div>
                <div class="col-11">
                  <p
                    style="
              margin-top: 10px;
              font-size: 1.3rem;
              "
                  >
                    นาย สมพงศ์ พรประเสริฐกิจ<br />
                    <span style="font-size: 0.8rem">
                      สังกัด:
                    </span>
                  </p>
                </div>
                <div class="col-12" style="margin-top: -30px;">
                  <v-divider></v-divider>
                </div>
              </div>
            </div>
          </div>

          <!-- <youtube
        :video-id="videoId"
        ref="youtube"
        @playing="playing"
        @paused="pause"
      ></youtube>
      <button @click="playVideo">play</button> -->
        </div>
      </div>
    </div>
    <b-modal
      id="banner-fullscreen-modal"
      v-model="showFullScreen"
      centered
      scrollable
      size="xl"
      header-class="modal__header"
      footer-class="modal__footer"
      body-class="modal__body"
      hide-footer
      @hidden="onHidden"
    >
      <div class="form-group">
        <label for="tel"><b>ระบุรหัสเข้าห้องเรียน</b></label>
        <input
          type="text"
          class="form-control"
          placeholder="ระบุรหัสเข้าห้องเรียนที่ได้รับจากครูผู้สอน"
        />
      </div>
      <button
        class="btn btn-lg btn-primary btn-block form-group"
        :disabled="checkingSignIn || otpTimeout > 0"
        @click.prevent="signIn"
      >
        ยืนยัน
      </button>
    </b-modal>
  </page-content>
</template>

<script>
import PageContent from "../../components/layout/PageContent";
// import DoTest from "../../components/modal/DoTest";
// import VueYoutube from 'vue-youtube'
// import PageTitle from "../components/layout/PageTitle";
// import BannerImageHome from "../components/carousel/BannerImageHome";
// import News from "../components/carousel/News";
// import Reason from "../components/carousel/Reason";

export default {
  components: {
    PageContent,
    // DoTest
    // VueYoutube
    // PageTitle,
    // BannerImageHome,
    // News,
    // Reason,
  },

  data() {
    return {
      showFullScreen: false,
      videoId: "V8yp_IaRrxg",
      currentTime: 0,
      t: null,
      count: 15,
      isShowTest: false,
    };
  },
  methods: {
    playVideo() {
      this.player.playVideo();
    },
    countdown() {
      console.log(this.count);
      // starts countdown
      if (this.count === 0 && !this.isShowTest) {
        // time is up
        this.player.pauseVideo();
        this.isShowTest = true;
      } else {
        this.count--;
        this.t = setTimeout(this.countdown, 1000);
      }
    },
    playing() {
      console.log("we are watching!!!");
      this.countdown();
    },
    pause() {
      console.log("pause!!!");
      clearTimeout(this.t);
    },
    onDeleteSuccess() {
      this.isShowTest = true;
      console.log("Do!!");

      // let routeData = this.$router.resolve({name: '/do-test'});
      window.open(
        "https://e-learning.xn--l3cgao1bcb5b5d8hf8b6f.com/do-test",
        "_blank"
      );
      // window.open('/')
    },
    onDeleteNot() {
      console.log("Not Do!!");
      // window.open('/')
      this.isShowTest = true;
      this.player.playVideo();
    },
    openAttend() {
      this.showFullScreen = true;
    },
  },
  computed: {
    player() {
      return this.$refs.youtube.player;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  &___width-override {
    max-width: 1440px !important;

    @media only screen and (max-width: 1660px) and (min-width: 1420px) {
      max-width: 1140px !important;
    }
    @media only screen and (max-width: 1419px) and (min-width: 1140px) {
      max-width: 960px !important;
    }

    @media only screen and (max-width: 1139px) and (min-width: 770px) {
      max-width: 760px !important;
    }

    @media (max-width: 760px) {
      max-width: 360px !important;
    }
  }
}
</style>
