<template>
  <page-content>
    <!-- <img src="@/assets/mock/header.png" style="    width: 100%;" /> -->
    <!-- <page-title
      heading="ยินดีต้อนรับเข้าสู่เว็บไซต์ E-LEARNING D.A.R.E. Thailand"
    ></page-title> -->

    <!-- <banner-image-home></banner-image-home> -->
    <div
      :style="{
        backgroundImage: `url(${require('@/assets/backgroup_home.png')})`,
      }"
    >
    <div style="padding: 3rem">
      <h3 class="mt-4 text-center" style="color: #fff;"><b>หลักสูตรที่ลงทะเบียนแล้ว</b></h3>
      <br />
      <div
        class="row mb-4"
        style="padding-left: 5%; padding-right: 5%; margin-top: -30px"
      >
        <div class="col-3">
          <a>
            <p
              class="mt-4 text-center"
              align-items-center
              style="
                text-decoration: underline;
                color: #fff;
                text-decoration-color: #fff;
                text-decoration-thickness: 3px;
                font-size: 20px;
              "
              @click="buyItem(price)"
            >
              <b>ทั้งหมด</b>
            </p>
          </a>
        </div>
        <div class="col-3">
          <p
            class="mt-4 text-center"
            style="padding-left: 10px; color: #fff; font-size: 20px"
          >
            กำลังเรียนอยู่
          </p>
        </div>
        <div class="col-3">
          <p
            class="mt-4 text-center"
            style="padding-left: 10px; color: #fff; font-size: 20px"
          >
            รอการยืนยันลงทะเบียน
          </p>
        </div>
        <div class="col-3">
          <p
            class="mt-4 text-center"
            style="padding-left: 10px; color: #fff; font-size: 20px"
          >
            ศึกษาสำเร็จ
          </p>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-12">
          <p
            class="mt-4 text-left"
            style="padding-left: 10px; color: #fff; font-size: 20px"
          >
            <b>กำลังเรียนอยู่</b>
          </p>
        </div>
        <div class="col-4">
          <base-card style="width: 100%"> </base-card>
        </div>
        <div class="col-4">
          <base-card style="width: 100%"> </base-card>
        </div>
      </div>
      <!-- <div class="row mb-4">
        <div class="col-4">
          <base-card style="width: 100%"> </base-card>
        </div>
        <div class="col-4">
          <base-card style="width: 100%"> </base-card>
        </div>
        <div class="col-4">
          <base-card style="width: 100%"> </base-card>
        </div>
      </div> -->

      <div class="row mb-4">
        <div class="col-12">
          <p
            class="mt-4 text-left"
            style="padding-left: 10px; color: #000; font-size: 20px"
          >
            รอการยืนยันลงทะเบียน
          </p>
        </div>
         <div class="col-4">
          <b-card
      overlay
      img-src="https://yuzudigital.com/eng1.jpeg"
      text-variant="white"
      :footer-bg-variant="bgFooter"
      footer-text-variant="white"
    >
      <div class="text-right">
        <b-button href="/reason-registered" variant="primary" style="padding: 2px;">
          <p class="m-0" style="font-size: 0.8rem;">ดูรายละเอียด</p>
        </b-button>
      </div>
      <template #footer>
        <p>
          <b> English Language Company </b>
        </p>
        <!-- <b-progress height="0.3rem" max="100" style="background-color: hsl(45, 97%, 75%)">
          <b-progress-bar
            value="65"
            style="background-color: white"
          ></b-progress-bar>
        </b-progress> -->
        <!-- <p class="pt-3" style="color: hsl(45, 97%, 90%); font-size: 0.8rem">เรียนไปแล้ว 65%</p> -->
      </template>
    </b-card>
        </div>
        <!-- <div class="col-4">
          <base-card style="width: 100%"> </base-card>
        </div>
        <div class="col-4">
          <base-card style="width: 100%"> </base-card>
        </div> -->
      </div>
     
      <!-- <div class="row mb-4">
        <div class="col-4">
          <base-card style="width: 100%"> </base-card>
        </div>
        <div class="col-4">
          <base-card style="width: 100%"> </base-card>
        </div>
        <div class="col-4">
          <base-card style="width: 100%"> </base-card>
        </div>
      </div> -->

      <div class="row mb-4">
        <div class="col-12">
          <p
            class="mt-4 text-left"
            style="padding-left: 10px; color: #000; font-size: 20px"
          >
            ศึกษาสำเร็จ
          </p>
        </div>
        <div class="col-4">
          <img src="@/assets/mock/reason-rate.png" style="width: 100%" />
        </div>
        <div class="col-4">
          <img src="@/assets/mock/reason-rate.png" style="width: 100%" />
        </div>
        <div class="col-4">
          <img src="@/assets/mock/reason-rate.png" style="width: 100%" />
        </div>
      </div>
    </div>
    </div>
  </page-content>
</template>

<script>
import PageContent from "../../components/layout/PageContent";
// import PageTitle from "../components/layout/PageTitle";
// import BannerImageHome from "../components/carousel/BannerImageHome";
// import News from "../components/carousel/News";
// import Reason from "../components/carousel/Reason";
import BaseCard from "../../components/card/BaseCard";
export default {
  components: {
    PageContent,
    BaseCard,
    // PageTitle,
    // BannerImageHome,
    // News,
    // Reason,
  },

  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.container {
  &___width-override {
    max-width: 1440px !important;

    @media only screen and (max-width: 1660px) and (min-width: 1420px) {
      max-width: 1140px !important;
    }
    @media only screen and (max-width: 1419px) and (min-width: 1140px) {
      max-width: 960px !important;
    }

    @media only screen and (max-width: 1139px) and (min-width: 770px) {
      max-width: 760px !important;
    }

    @media (max-width: 760px) {
      max-width: 360px !important;
    }
  }
}
</style>
